<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Coupon_Name')">
                <el-input
                  v-model.trim="formInline.couponRuleName"
                  filterable
                  placeholder="输入优惠券名称"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Coupon_type')">
                <el-select v-model.trim="formInline.couponType">
                  <el-option label="全部" value="" />
                  <el-option label="现金券" :value="0" />
                  <el-option label="折扣券" :value="1" />
                  <el-option label="全免券" :value="2" />
                  <el-option label="小时券" :value="3" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.status" filterable placeholder="">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="有效" :value="0"></el-option>
                  <el-option label="失效" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</AuthorityComponent
              >
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.add']"
                type="primary"
                icon="el-icon-plus"
                @click="$router.push('/addEditCoupon')"
                >添加</AuthorityComponent
              >
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.id"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="70"
            v-if="!(!buttons.view && !buttons.edit && !buttons.down && !buttons.delete)"
          >
            <template slot-scope="scope">
              <el-dropdown @command="dropdownClick($event, scope.$index, scope.row)">
                <el-button type="text" size="small" style="padding: 0">
                  操作
                  <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.view']"
                    command="a"
                    >查看</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    v-if="scope.row.status == 0"
                    command="b"
                    >修改</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.delete']"
                    v-if="scope.row.canBeDeleted"
                    command="c"
                    >删除</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.down']"
                    v-if="scope.row.status == 0"
                    command="d"
                    >下架</AuthorityComponent
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "couponRules",
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      couponList: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          id: 1,
          prop: "couponName",
          label: "优惠券名称",
          width: "",
        },
        {
          id: 2,
          prop: "couponMode",
          label: "模式",
          formatter: (row, column) => {
            if (row.couponMode === 0) {
              return "自有券";
            } else if (row.couponMode === 1) {
              return "合作券";
            }
          },
        },
        {
          id: 3,
          prop: "couponTypeValue",
          label: "优惠券类型",
        },
        {
          id: 4,
          prop: "faceValue",
          label: "面值",
          width: "",
          formatter: (row, column) => {
            switch (row.couponType) {
              case 0:
                return row.faceValue / 100 + "元";
              case 1:
                return row.faceValue + "%";
              case 2:
                return "-";
              case 3:
                return row.faceValue ? (row.faceValue / 60).toFixed(1) + "小时" : "-";
              default:
                return "";
            }
          },
        },
        {
          id: 6,
          prop: "delayTime",
          label: "有效日期",
          width: "",
          formatter: (row, column) => {
            if (row.effectType == 0) {
              if (!row.effectStartTime || !row.effectEndTime) {
                return "";
              }
              return row.effectStartTime.split(" ")[0] + " 至 " + row.effectEndTime.split(" ")[0];
            } else {
              let surplus = row.delayTime;
              let days = parseInt(row.delayTime / (24 * 60));
              days = days > 0 ? days : 0;
              surplus = surplus - days * 24 * 60;
              let hours = parseInt((row.delayTime - days * 24 * 60) / 60);
              hours = hours > 0 ? hours : 0;
              surplus = surplus - hours * 60;
              let minutes = surplus;
              minutes = minutes > 0 ? minutes : 0;
              return "自领取后" + days + "天" + hours + "时" + minutes + "分内有效";
            }
          },
        },
        {
          prop: "useClient",
          label: "可用终端",
          formatter: (row, column) => {
            if (!row.useClient) {
              return;
            }
            return row.useClient
              .replace(1, "APP")
              .replace(2, "微信公众号")
              .replace(3, "微信小程序")
              .replace(4, "支付宝生活号")
              .replace(5, "支付宝小程序");
          },
        },
        {
          id: 7,
          prop: "scope",
          label: "使用范围",
          width: "",
          formatter: (row, column) => {
            if (row.scope === 1) {
              return "全部";
            } else if (row.scope === 2) {
              return "按道路/车场";
            } else if (row.scope === 3) {
              return "按商户";
            }
          },
        },
        {
          id: 8,
          prop: "status",
          label: this.$t("list.state"),
          width: "",
          formatter: (row, column) => {
            if (row.status === 0) {
              return "有效";
            } else if (row.status === 1) {
              return "失效";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        couponRuleName: "",
        couponType: "",
        status: "",
      },
      buttons: this.$route.meta.authority.button,
    };
  },
  methods: {
    dropdownClick(com, index, row) {
      if (com == "a") {
        this.$router.push({
          path: "/couponDetail",
          query: row,
        });
      }
      if (com == "b") {
        const opt = {
          method: "get",
          url: "/acb/2.0/coupon/byId/" + row.couponRuleId,
          data: { couponRuleId: row.couponRuleId },
          success: (res) => {
            if (res.state === 0) {
              this.$router.push({
                path: "/addEditCoupon",
                query: row,
              });
            }
          },
        };
        this.$request(opt);
      }
      if (com == "c") {
        this.close(row);
      }
      if (com == "d") {
        this.under(row);
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    changeDetail(type) {
      if ((this.index === 0 && type === "pre") || (this.index === 14 && type === "next")) {
        this.$alert("没有更多数据了", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      } else {
        type === "pre" ? this.index-- : this.index++;
        let opt = {
          url: "/sym_analysis/api/v1/parking/queryParkRecordDetail",
          data: {
            parkRecordId: this.tableData[this.index].parkRecordId,
          },
          success: (res) => {
            this.infoDetail = res.data.ParkRecordVO;
          },
        };
        // getData(opt);
      }
    },
    showDetail(index, obj) {
      this.$router.push({
        path: "/parkDetail",
        query: obj,
      });
    },
    under({ couponRuleId }) {
      let opt = {
        method: "get",
        url: "/acb/2.0/coupon/takeOff/" + couponRuleId,
        data: {
          couponRuleId: couponRuleId,
        },
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
        },
      };
      this.$confirm("确认将该优惠券下架？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    close({ couponRuleId }) {
      let opt = {
        method: "get",
        url: "/acb/2.0/coupon/delete/" + couponRuleId,
        data: {
          couponRuleId: couponRuleId,
        },
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
        },
      };
      this.$confirm("确认将该优惠券删除？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 搜索
    searchData(pageNum) {
      this.tableData = [];
      this.loading = true;
      const tempData = {
        page: this.pageNum,
        pageSize: this.pageSize,
      };
      if (this.formInline.couponRuleName) {
        tempData.couponRuleName = this.formInline.couponRuleName;
      }
      if (
        this.formInline.couponType === 0 ||
        this.formInline.couponType === 1 ||
        this.formInline.couponType === 2 ||
        this.formInline.couponType === 3
      ) {
        tempData.couponType = this.formInline.couponType;
      }
      if (this.formInline.status === 0 || this.formInline.status === 1) {
        tempData.status = this.formInline.status;
      }
      const opt = {
        method: "get",
        url: "/acb/2.0/coupon/list",
        data: tempData,
        success: (res) => {
          this.tableData = res.value.list || [];
          this.total = res.value.total * 1;
          this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
  activated() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
